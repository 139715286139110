import React, { useState, useEffect } from 'react';
import { ConfigProvider, Badge, Space, Row, Drawer, Radio, Progress, Table, Upload, InputNumber, List, Tooltip, Image, Divider, FloatButton, Breadcrumb, Card, Layout, Form, Input, theme,  message, Steps, Button, Modal  } from 'antd';
import { ShoppingCartOutlined, RollbackOutlined , SmileOutlined, MessageOutlined, CheckCircleOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Link} from 'react-router-dom';
import axios from "axios";
import AWS from 'aws-sdk'
import "./App.css";
import logocheckout from '../img/stripe-badge-transparent.png';
import Footer from './Footer';
import image1item from '../img/image1.jpg'
import image2item from '../img/imagecouple.jpeg'
import image3item from '../img/image3.jpeg'
import image4item from '../img/4.jpeg'
import image5item from '../img/5.jpeg'
import image6item from '../img/6.jpeg'
import imagelovecoupe from '../img/logocouple.jpeg'
import { triggerFocus } from 'antd/es/input/Input';
// import { getCart, addToCart, getAllProducts, getProductsByCategory } from "../API/checkout.js.tmp";



const { Meta } = Card;


const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);



const { TextArea } = Input;
const { Header, Content, FooterS } = Layout;


const PositionMed = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    offset: 0,
    span: 6,
  },
};

const PositionMedButton = {
  labelCol: {
    offset: 1,
    span: 1,
  },
  wrapperCol: {
    offset: 5,
    span: 1,
  }
};

const PositionMedButtonImage = {
  labelCol: {
    offset: 10,
    span: 0,
  },
  wrapperCol: {
    offset: 10,
    span: 3,
  },
};




const dataimg = [
  {
    id: "1",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/couch-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2020/01/Sample-2-scaled.jpg'
    
  },
  {
    id: "2",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/kwik-e-mart8x10-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2020/01/Sample-10-scaled.jpg'
  },
  {
    id: "3",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/class-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2020/01/class-scaled.jpg'
  },
  {
    id: "4",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/HOUSE_without-homer-160x160.jpg',
    preview: image5item
  },
  {
    id: "5",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/wood2-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2020/01/wood2-scaled.jpg'
  },
  {
    id: "6",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/beach-1-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2020/01/beach-1-1024x676.jpg'
  },
  {
    id: "7",
    title: 'https://euamarelo.com/wp-content/uploads/2019/12/moes-out10x8-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2019/12/moes-out10x8-scaled.jpg'
  },
  {
    id: "8",
    title: 'https://euamarelo.com/wp-content/uploads/2019/12/moes8x10-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2019/12/moes8x10-scaled.jpg'
  },
  {
    id: "9",
    title: 'https://euamarelo.com/wp-content/uploads/2019/12/moes-tavern-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2019/12/moes-tavern-1024x819.jpg'
  },
  {
    id: "10",
    title: 'https://euamarelo.com/wp-content/uploads/2019/12/kitchen_1-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2019/12/kitchen_1-1024x768.jpg'
  },
  {
    id: "11",
    title: imagelovecoupe,
    preview: image2item
  },
  {
    id: "12",
    title: 'https://euamarelo.com/wp-content/uploads/2019/12/cinema-theatre-160x160.jpg',
    preview: 'https://euamarelo.com/wp-content/uploads/2019/12/cinema-theatre-scaled.jpg'
  },
  {
    id: "13",
    title: 'https://euamarelo.com/wp-content/uploads/2020/01/springfield-160x160.jpg ',
    preview: 'https://euamarelo.com/wp-content/uploads/2020/01/springfield-1229x1536.jpg'
  },


];



      const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };




     

const App: React.FC = () => {


  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleRealoadChrome = () => {
    setIsModalOpen(false);

    let reloadCountInitial = sessionStorage.getItem('reloadCount')
    
    if(!reloadCountInitial) {  
      sessionStorage.setItem('reloadCount', String(true));
      window.location.reload()  
    }


  };

  const success = () => {
    Modal.success({
      content: 'some messages...some messages...',
    });
  };

 


  const [clientSecret, setClientSecret] = useState("");


    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };



  const {
    token: { colorBgContainer },
  } = theme.useToken();


  const [stepForm] = Form.useForm();
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  // const [priceitem, SetPriceitem] = useState('price_1O5aLpDlXOeCOMymTuSegT4A');


  const nextStep1 = (BackgroundImage) => {
    sessionStorage.setItem('BackgroundImage', BackgroundImage)
    setCurrent(current + 1); 
  

  };

  // SHOPPING CART
  const nextStep2 = (FirstName, lastName, email, numberPpl, PhotoDetails) => {
    
    let finalprice;
    let displayPice

    if (numberPpl===1) {
      // Prod
      finalprice='price_1ORzfGDlXOeCOMymzkEZyCP0'
      // SBX
      // finalprice='price_1O5aLpDlXOeCOMymTuSegT4A'
      displayPice='21.99'
    }  
    
    else if (numberPpl ===2) {
      //Prod
      finalprice='price_1ORzi3DlXOeCOMymuaxmTlhD'
      // SBX  
      //finalprice='price_1O5b1LDlXOeCOMymswVeieW0'
      displayPice='33.99'
    }

    else if (numberPpl ===3) {
      //Prod
      finalprice='price_1OAOpIDlXOeCOMym2e6CIUfU'
      // SBX
      //finalprice='price_1OAO6mDlXOeCOMymoMYzNtOv'
      displayPice='64.99'
    }

    else if (numberPpl ===4) {
      //Prod
      finalprice='price_1OAOqyDlXOeCOMymdvCPSAKe'
      // SBX
      //finalprice='price_1OAOAoDlXOeCOMym9LIeIaVC'
      displayPice='79.99'
    }

    else if (numberPpl ===5) {
      //Prod
      finalprice='price_1OAOrlDlXOeCOMym50FfsZcK'
      // SBX
      //finalprice='price_1OAOC6DlXOeCOMymtZYwU0zu'
      displayPice='85.99'
    }

    else if (numberPpl ===6 ) {
      //Prod
       finalprice='price_1OAOsMDlXOeCOMymf2kDfODR'
      // SBX
      //finalprice='price_1OAODdDlXOeCOMymA6BCpJZs'
      displayPice='100.99'
    }


    sessionStorage.setItem('FirstName', FirstName)
    sessionStorage.setItem('lastName', lastName)
    sessionStorage.setItem('email', email)
    sessionStorage.setItem('numberPpl', numberPpl)
    sessionStorage.setItem('PhotoDetails', PhotoDetails)
    sessionStorage.setItem('PriceItem', finalprice)
    sessionStorage.setItem('displayPice', displayPice)

    setCartDrawerOpen(true);
    setCurrent(current +1);




    


  };

  const prev = () => {
    setCurrent(current - 1);
  };


  const selectbackgorund = (backgroundPicture) => {
    
    sessionStorage.setItem('backgroundPicture', backgroundPicture)
    console.log("backgroundPicture"+backgroundPicture)
    
  }   


  // STEP 1 - SELECT BACKGROUD
  const Step1Form = () => {
    return (
      <>

<ConfigProvider
    theme={{
      token: {
        colorPrimary: '#fad13e',
        borderRadius: 2,
        colorBgContainer: '#fad13e',
        colorBgElevated: '#f6ffed',
        colorIcon: '#fad13e',
        colorIconHover:'#00b16b',
        boxShadow: 'inset 0 0 10px #999',
        borderRadius: 5,
        
        

      },
    }}
  >

      <Modal
            title="It's Me Yellow Checkout time, woohoo!" 
            okText= "Continue"
            keyboard= "false"
            centered="true"
            footer={[
              <Button key="Continue" onClick={handleRealoadChrome} maskClosable={handleRealoadChrome} onCancel={handleRealoadChrome}>
                Continue
              </Button>]}
            open={isModalOpen} onCancel={handleRealoadChrome} onOk={handleRealoadChrome} maskClosable={handleRealoadChrome} >
             <p>(1) Click on <u>image preview</u> to see background details.</p>
             <p>(2) Ready? <u>Click on <CheckCircleOutlined /></u> to pick background. </p>
      </Modal>
      </ConfigProvider>


      


  
      <Card title="Please, Select the Background:" >
      <List
      itemLayout="horizontal"
      dataSource={dataimg}
      grid={{xs:1,sm:2,md:3,lg:4,xl:5,xxl:6}}
      renderItem={(item, index) => {
          return (
            <Card key={item.id} style={{height:280}}>
            <Image 
            src={item.title}
            preview={{
              src: item.preview,
            }} 
            ></Image>
            <Divider style={{height:0}} />
            <Tooltip title="Select background!">
              <Button icon={<CheckCircleOutlined />} onClick={() => nextStep1(item.title)} />
            </Tooltip>
            </Card> 
            );
     }}
      ></List>

      

   
      <FloatButton 
        // onClick={() => console.log('click')} 
        href='mailto:sales@me-yellow.com?subject=Sales Questions&body=Hi - I have the following question(s):'
        type="primary" 
        icon={<MessageOutlined />}
         style={{ top: '3vh' , right: '4vh' }}  />

      </Card>
      </>
    );
  };




  // STEP 2 - REGISTRATION
  const Step2Form = () => {


  const S3_BUCKET ='meyellowclientphoto';
  const REGION ='us-east-1';


  AWS.config.update({
    accessKeyId: 'AKIAUJJFV5TSSEJN6WHL',
    secretAccessKey: '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'
  })

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
  })

 

    const [FirstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [numberPpl, setNumberPpl] = useState(1);
    const [PhotoDetails, setPhotoDetails] = useState('');
    const [TC, setTC] = useState();
    const [uploadImageFilename, setuploadImageFilename] = useState('');
    // const S3_API_ENDPOINT = "https://to0ggv8913.execute-api.us-east-1.amazonaws.com/default/getPresignedUrl-1"
  
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [progress, setProgress] = useState(0);
    

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = async options => {
        const {onSuccess, onError, file} = options;
        const timestamp = Date.now();
        const fileName = `${timestamp}_${file.name}`;
        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: fileName
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) {
                   message.error('Error During Photo Upload! Please, reload the page and try again');
                   onError({ err });
                  }
                else
                  {
                  message.success('Photo Uploaded Successfully!');
                  onSuccess("Ok");
                  }
            })
    }


    const beforeUpload = (file: RcFile) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const handleOnChange = ({ file, fileList, event }) => {
      // console.log(file, fileList, event);
      //Using Hooks to update the state to the current filelist
      setDefaultFileList(fileList);
      //filelist - [{uid: "-1",url:'Some url to image'}]
    };

    // ########### ITEM PRICE ###############
    const [itemPrice1, setitemPrice1] = useState(21);
    const [itemPrice2, setitemPrice2] = useState(33);
    const [itemPrice3, setitemPrice3] = useState(64);
    const [itemPrice4, setitemPrice4] = useState(79);
    const [itemPrice5, setitemPrice5] = useState(85);
    const [itemPrice6, setitemPrice6] = useState(100);




    const [value, setValue] = useState(1);
    const [pricecheckout, setpricecheckout] = useState('$0.00');
    const [imagecheckout, setimagecheckout] = useState(image1item);
    const onChangeRadioNumberppl = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setpricecheckout("$" + e.target.value + ".99")

      if (e.target.value===itemPrice1) {
        setNumberPpl(1)
        setimagecheckout(image1item)
      }  
      else if (e.target.value===itemPrice2) {
        setNumberPpl(2)
        setimagecheckout(image2item)
      }  
      else if (e.target.value===itemPrice3) {
        setNumberPpl(3)
        setimagecheckout(image3item)
      }  
      else if (e.target.value===itemPrice4) {
        setNumberPpl(4)
        setimagecheckout(image4item)
      }  
      else if (e.target.value===itemPrice5) {
        setNumberPpl(5)
        setimagecheckout(image5item)
      }  
      else if (e.target.value===itemPrice6) {
        setNumberPpl(6)
        setimagecheckout(image6item)
      }  
       
    };


    const [open, setOpen] = useState(false);

    const showCheckout = () => {
      setOpen(true);
    };
  
    const onCloseCheckout= () => {
      setOpen(false);
    };


    const [checkedradiobutton, setCheckedRadioButton] = useState(false);


      const validateEmail = (rule, value, callback) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(value)) {
          callback('Invalid email address');
        } else {
          callback();
        }
      };

    return (
      
      <>
      
     <Form
      autoComplete="off"
      validateMessages={validateMessages}
      initialValues={{ FirstName: '', lastName: ''}}
      >


        
      <Card title="Please, Upload the picture with details below:" >
        
      <Form.Item {... PositionMed}
        onBlur ={(e) => setFirstName(e.target.value)} 
        name="FirstName"
        label="First Name:"
        rules={[
          {
            required: true,
          },
        ]}
      > 
      <input type="hidden" name="autoComplete" value="off" />
      <Input placeholder="First Name"  autoComplete="new-password" />
      
      </Form.Item>

          <Form.Item {...PositionMed} 
          onBlur={(e) => setLastName(e.target.value)} 
          name="lastName" 
          label="Last Name:"
          rules={[
                {
                  required: true,
                },]}
          >
          <input type="hidden" name="autoComplete" value="off" />
          <Input placeholder="Last Name" autoComplete="new-password" />
          </Form.Item>

          <Form.Item {...PositionMed} 
          onBlur={(e) => setEmail(e.target.value)} 
          name="email" 
          label="Email:"
          rules={[
                {
                  required: true,
                  message: 'Email is required',
                  validator: validateEmail 
                },]}
          >
          {/* <input type="hidden" name="autoComplete" value="off" /> */}
          <Input placeholder="example@example.com" autoComplete="new-password" />
          </Form.Item>
          
                
          
      <Form.Item {... PositionMed}
           name="Numberofppl"
           label="People/Pets: (Up to 6)"
          >

          <Radio.Group name='itemradiogr'
          onChange={onChangeRadioNumberppl} value={itemPrice1}  >
            <Radio.Button value={itemPrice1} onChange={() => setCheckedRadioButton(true)}>1</Radio.Button>
            <Radio.Button value={itemPrice2} onChange={() => setCheckedRadioButton(true)}>2</Radio.Button>
            <Radio.Button value={itemPrice3} onChange={() => setCheckedRadioButton(true)}>3</Radio.Button>
            <Radio.Button value={itemPrice4} onChange={() => setCheckedRadioButton(true)}>4</Radio.Button>
            <Radio.Button value={itemPrice5} onChange={() => setCheckedRadioButton(true)}>5</Radio.Button>
            <Radio.Button value={itemPrice6} onChange={() => setCheckedRadioButton(true)}>6</Radio.Button>
          </Radio.Group>

      </Form.Item> 

      

        <Row type="flex" justify="center" align="middle">    
        <Form.Item 
              name="CheckoutPrice"
          >
          <Card 
          hoverable={false}
          style={{ width: '25vh', border: '1px solid black'}}
          cover={<img alt="Cart Item" src={imagecheckout} />}
        >
          <Meta title="Price" size="large" description={pricecheckout} />
        </Card>

        </Form.Item>
        </Row>

          <Row type="flex" justify="center" align="middle">   
          <Form.Item 
                  name="photo" 
                  // label="Photo Upload "
         > 
          <div className="container">
          <Tooltip title="Click Me!">
          {/* <ImgCrop rotationSlider className="bg-yellow-500"> */}
          <Upload
            beforeUpload={beforeUpload}
            accept="image/*"
            maxFiles={1}
            customRequest={uploadFile}
            onChange={handleOnChange}
            listType="picture-circle"
            showUploadList={{ showPreviewIcon: false }} 
            defaultFileList={defaultFileList}
            className="image-upload-grid"
            multiple={false}
          >
            {defaultFileList.length >= 1 ? null : <div> Photo Upload Max 5MB</div>}
            </Upload>
            {/* </ImgCrop> */}
            </Tooltip>
            {progress > 0 ? <Progress percent={progress} /> : null}
             </div>

             <div>


    </div>
            
        </Form.Item>  
        </Row>
         

        <Form.Item {...PositionMed} 
        label="Details:"
        >
            <TextArea
            showCount
            maxLength={100}
            style={{ height: 80}}
            onBlur={(e) => setPhotoDetails(e.target.value)} 
            placeholder="Type any details you'd like to share with us: (i.e wedding ring, t-shirt color and etc.)"
          />
        </Form.Item>


        <Form.Item {...PositionMed} 
          label=" " 
          colon={false}
          name="disabled" 
          valuePropName="checked"
          // disableRemove={false}
          onChange={setTC} 
         >
            
            <Divider></Divider>
            <Link to='/terms'>
            <Button type="link">Terms & Conditions</Button>       
            </Link>
            <div>
            </div>

           </Form.Item>

        <Form.Item {...PositionMed} 
        label=" "
        colon={false}
        
        >

          <Button className="bg-yellow-500" type="primary" 
          disabled={ !email || !checkedradiobutton}
          style={{ margin: '0 8px' }} size="large" onClick={() => nextStep2(FirstName, lastName, email, numberPpl, PhotoDetails, uploadImageFilename)}>
            Add to Cart
          </Button>  

          
        </Form.Item>

      <FloatButton 
        // onClick={() => console.log('click')} 
        href='mailto:sales@me-yellow.com?subject=Sales Questions&body=Hi - I have the following quetion(s):'
        type="primary" 
        icon={<MessageOutlined />}
         style={{ top: '3vh' , right: '4vh' }}  />

          
         
         

        </ Card>

        </Form>
      </>
    );
  };


  
  // STEP 3 - CHECKOUT PAYMENTS
  const Step3Form = () => {

    const CheckoutDataSource = [
      {
        item: 'Photo Yellow',
        Participants: sessionStorage.getItem("numberPpl"),
        email: sessionStorage.getItem("email"),
        price: sessionStorage.getItem("displayPice")
      },
    ];

   sessionStorage.setItem('badgeCheckout', String('1'));


    const FinalChckoutStripe = () => (

      <section>

        {/* "proxy": "http://localhost:4242", */}
        {/* <form action="http://localhost:4242/create-checkout-session" method="POST">    */}

        <form action="https://server.me-yellow.com/create-checkout-session" method="POST">  
          <input type="hidden" name="PriceItem" value={sessionStorage.getItem('PriceItem')} />  
          <input type="hidden" name="email" value={sessionStorage.getItem('email')} />  
          <input type="hidden" name="FirstName" value={sessionStorage.getItem('FirstName')} />  
          <input type="hidden" name="lastName" value={sessionStorage.getItem('lastName')} />  
          <input type="hidden" name="PhotoDetails" value={sessionStorage.getItem('PhotoDetails')} />  
          <input type="hidden" name="PhotoFileName" value={sessionStorage.getItem('PhotoFileName')} />  
          <input type="hidden" name="BackgroundImage" value={sessionStorage.getItem('BackgroundImage')} />  
          <input type="hidden" name="S3FileUpload" value={sessionStorage.getItem('S3FileUpload')} />  
          <input type="hidden" name="numberPpl" value={sessionStorage.getItem('numberPpl')} />  
          <div
            style={{
              justifyContent: "center",
              alignItems: "center"
            }}
          >
          <Space.Compact style={{ width: '25vh', justifyContent: "center", justifyContent: "center"}}>
          <Input placeholder="CUPOM CODE" />
           <Button className="bg-yellow-500" type="primary" onClick={() => message.error('Cupom not valid!')}>Apply</Button>
           </Space.Compact>
          
           <Divider></Divider>
           <Space.Compact style={{ width: '31vh', justifyContent: "center",
              justifyContent: "center", }}>
          <Button className="bg-yellow-500" type="primary" htmlType="submit" shape='round' size="mdedium" icon={<CreditCardOutlined />} style={{alignItems: "center"}}>
            CHECKOUT
          </Button>
          </Space.Compact>
          <Divider></Divider>
          </div>

        </form>
        

      </section>
    
    
    );

    return (
      <>
  
        <Drawer
          open={cartDrawerOpen}
          onClose={() => {
            setCartDrawerOpen(false);
          }}
          title="Shopping Cart"
          contentWrapperStyle={{ width: '40vh'}}
          >

         <Table style={{ marginBottom: "2vh"}} 
          dataSource={CheckoutDataSource}
          pagination={false}
          columns={[
            {
              title: "Item",
              dataIndex: "item",
              key: "item",
            },
            {
              title: "Figure(s)",
              dataIndex: "Participants",
              key: "Participants",

            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",

            },
          ]}
       

        />
        
        <>

        
        {/* <Space.Compact style={{ width: '31vh', alignItems: "center", justifyContent: 'center' }}> */}
        <FinalChckoutStripe />
          {/* </Space.Compact> */}

          <Image  
              preview={false}
              src={logocheckout} 
              alt="Stripe Checkout"
              style={{ 
              width: '500vh', 
              alignItems: "center",
              justifyContent: 'center'
              // backgroundColor: "red", 
              // alig: "center"
            }} />

      </>
      </Drawer>




      <Card title="Please, Proceed with Payment" >   
      {/* <Message message={message} /> */}

      <FinalChckoutStripe />
      <Space.Compact style={{ width: '15vh',}}>
        
        </Space.Compact>

        <Image  
            preview={false}
            src={logocheckout} 
            alt="Stripe Checkout"
            style={{ 
            width: 520, 
            alignItems: "center",
            justifyContent: 'center'
            // backgroundColor: "red", 
            // alig: "center"
          }} />


      </Card>
      </>
    );
  };


  const steps = [
    {
      title: 'Select Background',
      content: <Step1Form />,
      
    },
    {
      title: 'Upload Photo',
      content: <Step2Form />
    },
    {
      title: 'Payment',
      content: <Step3Form />,
      icon: <CreditCardOutlined />,
    },
    {
      title: 'Done',
      content: 'Last-content',
      icon: <SmileOutlined />,
    },
  ];




  const items = steps.map((item) => ({ title: item.title, icon: item.icon }));

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  // ##### SHOPPING CART
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [checkoutDrawerOpen, setCheckoutDrawerOpen] = useState(false);

  let badgeCheckout = sessionStorage.getItem('badgeCheckout')


  return (
    <Layout className="layout">
      <Header style={{ display: 'flex', alignItems: 'center', background:'#ffd90f', 
      backgroundImage: "url(" + "https://www.freepnglogos.com/uploads/donut-png/onlinelabels-clip-art-rainbow-sprinkles-donut-30.png" + ")",
      backgroundPosition: 'center',
      // backgroundSize: 'cover',
      backgroundSize: 50,
      backgroundRepeat: 'no-repeat'
      
    }}>
      <Badge
        onClick={() => {
          setCartDrawerOpen(true);
        }}
        count={badgeCheckout}
        color='red'
        className="soppingCartIcon"
      >
        <ShoppingCartOutlined style={{ fontSize: '4vh', color: '#08c' }}/>
      </Badge>
    </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          {/* <Breadcrumb.Item>Checkout</Breadcrumb.Item> */}
          {/* <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
        {/* <div className="site-layout-content" style={{ background: colorBgContainer }}> */}
          


        <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div style={{ marginTop: 24 }}>

        {current === steps.length - 1 && (
          <Button className="bg-yellow-500" type="primary" onClick={() => message.success('Processing complete!')}>
            Done  
          </Button>
        )}
        {current > 0 && (
          <>




      {/* <FinalChckoutStripe />
        <Space.Compact style={{ width: '31vh' }}>
          <Input placeholder="CUPOM CODE" />
           <Button className="bg-yellow-500" type="primary" onClick={() => message.error('Cupom not valid!')}>Apply</Button>
          </Space.Compact>
          <Divider></Divider>
          <Image  
              preview={false}
              src={logocheckout} 
              alt="Stripe Checkout"
              style={{ 
              width: 520, 
              alignItems: "center",
              justifyContent: 'center'
              // backgroundColor: "red", 
              // alig: "center"
            }} /> */}




          <Button icon={< RollbackOutlined />} style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>   

          </>
 
        )}
      </div>
    </>
      </Content>
      <Footer></Footer>

      

    </Layout>





  

    
  );


};

export default App;
