import React from 'react';
import { Layout, Form, Button  } from 'antd';
import {HomeOutlined } from '@ant-design/icons';
import { Link} from 'react-router-dom';
import "./App.css";


const { Header, Content, Footer } = Layout;

const PositionMed = {
  labelCol: {
    span: 11,
  },
  wrapperCol: {
    offset: 0,
    span: 5,
  },
};


const App: React.FC = () => {


  return (

    <Layout className="layout">
      <Header style={{ display: 'flex', alignItems: 'center', background:'#ffd90f', 
      backgroundImage: "url(" + "https://www.freepnglogos.com/uploads/donut-png/onlinelabels-clip-art-rainbow-sprinkles-donut-30.png" + ")",
      backgroundPosition: 'center',
      // backgroundSize: 'cover',
      backgroundSize: 50,
      backgroundRepeat: 'no-repeat'
      
      
      }}>
        <div className="demo-logo" />
      </Header>
      <Content style={{ padding: '0 50px' }}>

      Terms and Conditions for Digital Image Selling Service

1. Acceptance of Terms

By accessing or using the digital image selling service (referred to as "Service"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you should not use the Service.

2. Definitions

"Service Provider" refers to the entity or individuals providing the digital image selling service.
"User" refers to any person or entity accessing or using the Service.
3. Registration and User Accounts

a. To use the Service, Users may be required to create an account. All information provided during registration must be accurate and up-to-date.

b. Users are responsible for maintaining the security of their account information, including login credentials. Any activity that occurs under the User's account is their responsibility.

4. Image Upload and Licensing

a. Users may upload images to the Service. By uploading images, the User represents and warrants that they have the right to do so and that they have all necessary rights and permissions to license these images through the Service.

b. Users grant the Service Provider a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and display uploaded images for the purpose of promoting, marketing, or operating the Service.

5. Purchasing Images

a. Users may purchase images from the Service. The pricing, licensing terms, and usage rights for each image may vary and will be clearly specified during the purchase process.

b. Once purchased, the User is granted a non-exclusive, non-transferable license to use the image as specified in the licensing terms, which may include personal or commercial use.

6. Payments

a. Users agree to pay the specified fees for any images purchased through the Service. Payments are processed through secure payment gateways, and the User must provide accurate billing information.

b. The Service Provider reserves the right to change pricing or payment terms at any time and will notify Users of such changes.

7. Copyright and Intellectual Property

a. All images, content, and materials available on the Service are protected by copyright and other intellectual property rights. Users may not reproduce, distribute, or use these materials without proper authorization.

8. Refund Policy

a. Refunds for image purchases are subject to the Service Provider's refund policy, which will be clearly specified. Refunds are granted at the sole discretion of the Service Provider.

9. Termination of Service

The Service Provider reserves the right to terminate or suspend access to the Service at any time, with or without cause and with or without notice.

10. Privacy and Data Collection

The Service Provider may collect and use personal information as described in the Privacy Policy. By using the Service, Users consent to the collection and use of their personal information in accordance with the Privacy Policy.

11. Disclaimers and Limitations of Liability

The Service is provided "as is," and the Service Provider makes no warranties or guarantees regarding the availability, accuracy, or reliability of the Service. The Service Provider shall not be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages.

12. Governing Law

These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes shall be resolved in the courts of [Jurisdiction].

13. Contact Information

If you have any questions or concerns about these Terms and Conditions, please contact us at sales@meyellow.com.

      </Content>

      <Form.Item {...PositionMed} 
        colon={false}
        label=" "
        >
       <Link to='/'>
        <Button type="link" size="xlarge" icon={<HomeOutlined />}  >
          Home
        </Button>
        </Link>
        </Form.Item>
      

      
      <Footer style={{ textAlign: 'center' }}>ER Design ©2023</Footer>


    </Layout>

    
  );
};

export default App;
