import React, { useState, useEffect, useRef } from 'react';
import { Result, Breadcrumb, Card, Layout, Button,  theme, Steps} from 'antd';
import {SmileOutlined, CreditCardOutlined, HomeOutlined } from '@ant-design/icons';
import axios from "axios";
import { useRoutes, useLocation, Link} from 'react-router-dom';
import Checkout from './checkout';
import Footer from './Footer';



import {
  FiFacebook,
  FiInstagram,
} from "react-icons/fi"

const socialLinks = [
  {
    id: 1,
    icon: <FiFacebook />,
    url: "https://www.facebook.com/",
  },
  {
    id: 2,
    icon: <FiInstagram />,
    url: "https://www.instagram.com",
  },

];

const PositionMed = {
  labelCol: {
    span: 11,
  },
  wrapperCol: {
    offset: 0,
    span: 5,
  },
};

const PositionHome = {

  wrapperCol: {
    offset: 12,
    span: 1,
  },
};




export default function App() {
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const { Header, Content, Footers } = Layout;
  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();



           const SendEmailConfirmationOrder = async (firstName, lastName, email, numberPpl, PhotoDetails, PhotoFileName, BackgroundImage, S3FileUpload, PriceItem, OrderNumber) =>{
            // axios.post(`http://localhost:4242/emailconfirmation`, {
            axios.post(`https://server.me-yellow.com/confirmation`, {
                  orderNumber: OrderNumber,
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  numberPpl: numberPpl,
                  PriceItem: PriceItem,
                  PhotoFileName: PhotoFileName,
                  BackgroundImage: BackgroundImage,
                  S3FileUpload: S3FileUpload,
                  PhotoDetails: PhotoDetails
                  
                 },
                 {
                   headers: {
                     "Access-Control-Allow-Origin": "*",
                     "Access-Control-Allow-Headers": "Content-Type"
                   }
                 })
             //  .then(response =>   console.log(firstName, lastName, email, numberPpl, PhotoDetails, PhotoFileName, BackgroundImage, S3FileUpload, PriceItem, OrderNumber)
                 .then(response =>   console.log("user successfully registered! Email sent out!")  
                )
                 .catch((error) => {
                  <Link to='/paymentserror'> </Link>
                   console.log("Something went wrong!");
                 }); }


          //  CALL EMAIL FUNCTION IN PYTHON SERVER USING AXIOS
           
           const ref = useRef(null);
           useEffect(() => {

              let search = window.location.search;
              let params = new URLSearchParams(search);

              // https://checkout.me-yellow.com/paymentssuccess
              // ?OrderNumber=6584724043
              // &firstName=Erico
              // &lastName=Rodrigues
              // &numberPpl=1
              // &PhotoDetails=WERWEREWR
              // &PhotoFileName=donut.png
              // &BackgroundImage=https://euamarelo.com/wp-content/uploads/2020/01/couch-160x160.jpg
              // &S3FileUpload=https://myyellowfolder.s3.amazonaws.com/3626202.jpeg?AWSAccessKeyId=ASIAUJJFV5TS6TX2GPVW&Content-Type=image%2Fjpeg&Expires=1699067512&Signature=Ercz5I2m4MjHxr8WeD6Lq8HvaH0%3D&X-Amzn-Trace-Id=Root%3D1-65454147-09be5f71363c988d00feab29%3BParent%3D4ecd749812fde696%3BSampled%3D0%3BLineage%3D36b033cc%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJHMEUCIQD8Q%2BMSEyLZwMDP%2BSWUqJWRu9wpmHwcGLvrY%2FGKSTuSBAIgO%2BMwgW8YHz%2F6Os2p60gZ5BgYlivlqNPn%2F75C0WUtW00q9gIITBADGgwyOTQ4MjExNjIyMTMiDGIQ3C2OxGxcpHoAkSrTAh%2Fe9KD%2FMHS6brIfZ4zZ6PU9pxThcTdcEJgKSp8fs2U5IPXYrJ6G4COr9ZXSQKOzN2kxWQWOToL98wx7JsQyAGb%2B1PB%2FL%2BzAiAbef5RarNAT%2Bgl3M5duWekNkjjjHL%2BQS4iLqQ09%2BJfAvXcmpUruttBIzbIadDuDcT%2BEI05jYMrcbBe4nQHHTbuQ09zppCbBhzHyTaAzcVX1MG9IfSbp57dxyF3Z8oja%2F0RGbWmGDc9Mio0OLh4wQMCCll01SPIQKfIuz3hwqx9NmvUIxr3WvI%2ByUzHSMllFKEij9L%2FP7LwCvX5ytJGZ9%2BevStdeQRnKzV6aBykMEfMKyGAgFEjCmmeVQlFG58a3ljMGwlOh%2FaAHDlzdcvsLa%2FkqtlR1oUsyusIXFvwI7TOT8qYxZzm76mjWWBWLBrDzWKKC404zzlK4ug%2BDhRcrSGbdHzMGo1kik3rVUzDHgpWqBjqeAWyI3l27KxtXzGTyzTyS3sOT1T3SE55aCRLpSsNI88EqDtdsQOl7Oc0d7claH%2F6PzGDUZq2OC75ojlQ%2Ff6AIBFm9Y58MpAVXMW8qw01C01T6a6ptp3yKh9l5c%2B5sz%2FHJEE%2BxCHqcwQqHo%2FYxeYlHssmn3byGKHBL1XDvhfLJiOMdzPKONTIESALV5xk55LFUSKCbB3Au2pnTWxvtJXaJ
              // &PriceItem=price_1O5aLpDlXOeCOMymTuSegT4A
              // &OrderNumber=6584724043

              let firstName =  params.get('firstName') 
              let lastName =  params.get('lastName')
              let email =  params.get('email')
              let numberPpl =  params.get('numberPpl')
              let PhotoDetails =  params.get('PhotoDetails')
              let PhotoFileName =  params.get('PhotoFileName')
              let BackgroundImage =  params.get('BackgroundImage')
              let S3FileUploadtemp =  params.get('S3FileUpload')
              let S3FileUpload = S3FileUploadtemp.slice(0, S3FileUploadtemp.indexOf("?"));
              S3FileUpload = S3FileUpload.slice("https://myyellowfolder.s3.amazonaws.com/".length);
              console.log(S3FileUpload)
              let PriceItem =  params.get('PriceItem')
              let OrderNumber =  params.get('OrderNumber')

              // https://checkout.me-yellow.com/paymentssuccess?OrderNumber=0000000000&firstName=Erico&lastName=Rodrigues&email=erico.rod@icloud.com&numberPpl=1&PhotoDetails=&PhotoFileName=card_icon.png&BackgroundImage=https://euamarelo.com/wp-content/uploads/2020/01/kwik-e-mart8x10-160x160.jpg&S3FileUpload=https://s3.amazonaws.com/www.me-yellow.com/clientphotos/8319830.jpeg?AWSAccessKeyId=ASIAUJJFV5TS7CGPLYCU&Content-Type=image%2Fjpeg&Expires=1701930694&Signature=ELU3VgZ9AW6r6s81wKi2GtIeKJU%3D&X-Amzn-Trace-Id=Root%3D1-6570f196-752266a86d7d814d1aced116%3BParent%3D363d0ec6616973d3%3BSampled%3D0%3BLineage%3D36b033cc%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEC8aCXVzLWVhc3QtMSJIMEYCIQDgWrJ9EYlh%2Bn1LWqS8gMnqOab%2Bnf6Xa%2Fljy2wgHQWG4AIhAJMEf9JFbs1BJjGCtd9KxOwTO5hnqd%2FXELQAPLIDwdgkKv8CCJf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAxoMMjk0ODIxMTYyMjEzIgw7eYVXeeN15K652DYq0wJ19hE2fdYyeUzVn%2BdL9Ti%2FK%2BldO0KfMJGKLAnNNGEW4T3ydlLUPkzS1hqGuxsPtSk99dCwnEJfaJMRLzhC%2BKn1hwvJtatEvEKhskcWxqgNG1cVNW6pi5LHX1eXfilOzz2KKXlC5dPAYPJtX7uNviy3%2FqDAAyMQGrmzU8FooPux8HFOmlVQ8UAQoHtOi%2FFWROGBmjONosdWe2TlFKkEGe8SfEf8IBO%2B5PRhCZf3ZHkKrnQrQ9J2uRSrYZt072LSioncmFuvE407c7TlDQRDVBhEX4NcwUZgThIaKBe4b2wAAE38QD7PQs5K4JcPLt%2BYpr4h3DJJNepkywYxiCGX0h%2B1ztbFMz8onOWVhmniGloxPyA9zYs6wsftXxOt%2BQqoeAy4Ayk%2BENILudahysO%2F8R%2BWZqM%2BwgNJEMiwSiP8FAGdGMp52Edh8sMngXlIB%2FRlEC5xjSww1%2BLDqwY6nQHgz06FDt0wboXnMmKRQtCLa%2FSFVb6A8bnasNvtJlxX9SgwemEw5g3JHeyrEaw%2FhW5R1dY4QsPYnUf3%2BFeLGv8z66LfzE7FbTBJ5h%2FWsMq1wY2MiFcHAflckmqeXQUbjdV54A6jvGBtYOPXU%2Fmw6IZcZmi%2F1OFvtqa4vIQqrO1BSk0UqSPfvn%2BZyuu8iPOha9rHeTaf%2Bk1LEwvF2F9Z&PriceItem=price_1O5aLpDlXOeCOMymTuSegT4A&OrderNumber=1347934806
              



            
              SendEmailConfirmationOrder(firstName, lastName, email, numberPpl, PhotoDetails, PhotoFileName, BackgroundImage, S3FileUpload, PriceItem, OrderNumber);
        }, [])

  
  return (
    <Layout className="layout">
      <Header style={{ display: 'flex', alignItems: 'center', background:'#ffd90f', 
      backgroundImage: "url(" + "https://www.freepnglogos.com/uploads/donut-png/onlinelabels-clip-art-rainbow-sprinkles-donut-30.png" + ")",
      backgroundPosition: 'center',
      // backgroundSize: 'cover',
      backgroundSize: 50,
      backgroundRepeat: 'no-repeat'}}>
        <div className="demo-logo" />
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          {/* <Breadcrumb.Item>Checkout</Breadcrumb.Item> */}
          {/* <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
        {/* <div className="site-layout-content" style={{ background: colorBgContainer }}> */}
          
        <>
      <Steps current={current} 
      current="4"
      items={[
        {
          title: 'Select Background',
        },
        {
          title: 'Upload Photo',
        },
        {
          title: 'Payment',
          icon: <CreditCardOutlined />,
        },
        {
          title: 'Done',
          icon: <SmileOutlined />,
        },
      ]}
    />

      
      <div style={{ marginTop: 24 }}>
  
        <Card >
        <Result
            status="success"
            title="Successfully Purchased!"
            subTitle={`Order number: ${queryParameters.get("OrderNumber")}. Order will be processed between 5-10 business days.`}
            extra={[
              <Link to='/' >
              <Button type="link" icon={<HomeOutlined />}  >
              </Button>
              </Link>,
            ]}
          />



   
          </Card>
      </div>
    </>
      </Content>
      
      <Footer></Footer>
    </Layout>

  );

        }

