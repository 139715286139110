import React from 'react';
import { useRoutes} from 'react-router-dom';
import Checkout from './components/checkout';
import Terms from './components/terms.js';
import CheckoutPaymentSuccess from './components/SuccessPayments';
import CheckoutPaymentError from './components/ErrorPayments';




export default function App() {

  let element = useRoutes([
    {path: '/', element: <Checkout />},
    {path: '/paymentssuccess', element: < CheckoutPaymentSuccess/>},
    {path: '/paymentserror', element: < CheckoutPaymentError />},
    {path: '/terms', element: < Terms />},
  ]);



  return element;
}
