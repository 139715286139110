import React, { useState } from 'react';
import { Result, Breadcrumb, Card, Layout, Button,  theme, Steps} from 'antd';
import {SmileOutlined, CreditCardOutlined, HomeOutlined } from '@ant-design/icons';
import { useRoutes, useLocation, Link} from 'react-router-dom';
import Footer from './Footer';




export default function App() {

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(2);
  const { Header, Content } = Layout;
  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();



  return (
    <Layout className="layout">
      <Header style={{ display: 'flex', alignItems: 'center', background:'#ffd90f', 
      backgroundImage: "url(" + "https://www.freepnglogos.com/uploads/donut-png/onlinelabels-clip-art-rainbow-sprinkles-donut-30.png" + ")",
      backgroundPosition: 'center',
      // backgroundSize: 'cover',
      backgroundSize: 50,
      backgroundRepeat: 'no-repeat'}}>
        <div className="demo-logo" />

      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>

        </Breadcrumb>
    
          
        <>
      <Steps current={current} 
      status="error"
      items={[
        {
          title: 'Select Background',
        },
        {
          title: 'Upload Photo',
        },
        {
          title: 'Payment',
          icon: <CreditCardOutlined />,
        },
        {
          title: 'Done',
          icon: <SmileOutlined />,
        },
      ]}
    />

      
      <div style={{ marginTop: 24 }}>
  
        <Card >
        <Result
            status="error"
            title="Error during Purchase!"
            subTitle="No order has been submitted. Please, check with your bank, or try again."
            extra={[
              <Link to='/' >
              <Button type="link" icon={<HomeOutlined />}  >
              </Button>
              </Link>,
            ]}

          />

          </Card>
      </div>
    </>
    </Content>
      
      <Footer></Footer>
    </Layout>

  );



        }

